<template>
    <div class="view-app">
        <sidebar-view>
            <dashboard-container>
                <div class="card mb-7">
                    <div class="card-body">
                        <div class="card-header-padded">Your Referrals</div>

                        <p class="lead-2">
                            <strong>Share the link below!</strong>
                            <br />
                            Anyone who uses it to sign up to Thriftax will get a
                            10% discount at checkout.
                            <br />
                            <br />
                            For every one of your referrals that completes
                            checkout, you'll get 10% of their spending on their
                            first purchase.
                        </p>

                        <div class="input-group mb-3">
                            <input
                                ref="link"
                                type="text"
                                readonly
                                class="form-control custom-field"
                                v-model="link"
                            />
                            <div class="input-group-append" @click="copyLink()">
                                <span class="input-group-text btn"
                                    ><i class="fas" :class="copyLinkClass"></i>
                                </span>
                            </div>
                        </div>

                        <div v-if="referrals.length">
                            <p class="h4 mb-2 mt-5">
                                You shared your link with:
                            </p>
                            <p class="mb-3">
                                "Pending" means that the user has signed up, but
                                has not yet finished a tax return.
                            </p>
                            <div
                                class="border rounded p-3"
                                outlined
                                v-for="item in referrals"
                                :key="item.target"
                            >
                                <div class="mb-2">
                                    You Referred:
                                    <span class="font-weight-bold">{{
                                        item.referred
                                    }}</span>
                                </div>
                                <div v-if="item.fulfilled" class="p-0 m-0">
                                    <span class="h3">
                                        <badge color="success">
                                            <i class="fas fa-check"></i>
                                            Completed
                                        </badge>
                                    </span>
                                    <p class="font-weight-normal m-0 p-0 mt-2">
                                        Thanks for helping us grow! You'll be
                                        notified once we send our payouts. This
                                        will be by the end of this tax season.
                                    </p>
                                </div>
                                <div v-else class="h3 p-0 m-0">
                                    <badge color="info">
                                        <i class="fas fa-hourglass-half"></i>
                                        Pending
                                    </badge>
                                </div>
                            </div>
                        </div>
                        <p v-else class="lead-2 mt-6">
                            Anybody who uses your link to sign up to Thriftax
                            will show up here
                        </p>
                    </div>
                </div>
            </dashboard-container>
        </sidebar-view>
    </div>
</template>

<script>
import sidebarView from './sidebar/sidebar';
import dashboardContainer from './dashboard_container';

export default {
    components: {
        sidebarView,
        dashboardContainer,
    },
    data: () => ({
        referrals: [],
        link: '',
        email: null,
        emailInvalid: false,
        init: false,
        error: '',

        copySuccess: false,
    }),
    computed: {
        copyLinkClass() {
            if (this.copySuccess) return 'fa-check';
            else return 'fa-clipboard';
        },
    },

    methods: {
        copyLink() {
            this.$refs.link.focus();
            this.$refs.link.select();
            var successful = document.execCommand('copy');

            this.copySuccess = true;

            const self = this;
            setTimeout(() => {
                self.copySuccess = false;
            }, 1500);
        },
        createReferral() {
            if (this.init === false && this.email === null) {
                this.emailInvalid = true;
            }
            if (this.emailInvalid) {
                return;
            }
            this.$store.getters.client
                .post('/referrals/', { email: this.email })
                .then((response) => {
                    this.referrals = response.data;
                })
                .catch((err) => {
                    this.error = err.response.data;
                });
        },
    },
    created() {
        this.$store.getters.client.get('/referrals/').then((response) => {
            this.link = response.data.link;
            this.referrals = response.data.referrals;
        });
    },
    watch: {
        email(newVal) {
            const emailRegEx = new RegExp(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            );
            if (this.init === false) {
                this.emailInvalid = false;
            }
            this.error = '';
            this.init = true;
            if (emailRegEx.test(newVal)) {
                this.emailInvalid = false;
            } else {
                this.emailInvalid = true;
            }
        },
    },
};
</script>
